


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import VueI18n, { LocaleMessages } from 'vue-i18n'

@Component({
	computed: {
		moment () {
			return moment
		},
	},
})
export default class DateInput extends Vue {
	@Prop() value!: string | null
	@Prop() label?: string | VueI18n.TranslateResult
	@Prop() max?: string
	@Prop() min?: string
	@Prop() disabled: boolean = false

	private menu = false
	private date: string | null = null
	private activePicker = 'YEAR'

	get rules (): ((v: string) => boolean | string | LocaleMessages)[] | boolean {
		return [
			(v?: string) => (!!v && this.isValidDate(v)) || this.$t('ky.rule.date.format'),
			(v: string) => this.min === undefined || v > this.min || this.$t('ky.rule.date.min', { min: this.min }),
			(v: string) => this.max === undefined || v < this.max || this.$t('ky.rule.date.max', { max: this.max }),
		]
	}

	private get getPicker (): string | null {
		return (this.value === null || this.value === '') ? (this.max !== undefined ? this.max : '2000-01-01') : null
	}

	@Watch('menu')
	openMenu (menu: boolean): void {
		menu && setTimeout(() => (this.activePicker = 'YEAR'))
	}

	save (date: string | null): void {
		this.date = date
		const menu: any = this.$refs.menu
		menu.save(date)
		this.$emit('input', this.value)
	}

	private isValidDate (dateString: string): boolean {
		const [year, month, day] = dateString.split('-')
		const date = new Date(Number(year), Number(month) - 1, Number(day))

		return date.getFullYear() === Number(year) &&
			date.getMonth() === Number(month) - 1 &&
			date.getDate() === Number(day)
	}
}
