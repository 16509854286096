




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueI18n, {LocaleMessages, TranslateResult} from 'vue-i18n'
import { getFormParameters, uploadVerificationFactorFile } from '@/clients/cpinblocks'
import { VerificationFactor } from '@/models/KYState'

@Component
export default class VerificationFactorFileInput extends Vue {
	@Prop() label?: string | VueI18n.TranslateResult
	@Prop() tooltip?: string | VueI18n.TranslateResult
	@Prop() verificationFactor!: VerificationFactor
	@Prop() rule?: boolean
	@Prop() externalSend?: boolean
	@Prop() repeatableId?: string
	@Prop() submit = false
  @Prop() extensionJPGOnly?: boolean

  private file: File | null = null
	private loading = false
	private sent: boolean | null = false
	private allowedExtensions: string[] = ['.png', '.jpeg', '.jpg', '.pdf']
	private render = true

  get message (): TranslateResult {
    if (this.extensionJPGOnly && this.extensionJPGOnly === true) {
      return this.$t('ky.rule.file.extensionJPGOnly')
    }
    return this.$t('ky.rule.file.extension')
  }

	get rules (): ((v?: File) => boolean | string | LocaleMessages)[] {
		return [
			(v?: File) => ((!!v && this.isValidFile(v))) || this.submit || (this.rule !== undefined && this.rule) || this.message,
    ]
	}

	@Watch('rule')
	@Watch('submit')
	async updateRender (): Promise<void> {
		this.render = false
		await this.$nextTick()
		this.render = true
	}

	private isValidFile (file: File): boolean {
		return this.allowedExtensions.includes(file.name.substring(file.name.lastIndexOf('.')).toLowerCase())
	}

	@Watch('externalSend')
	private async sendFile (): Promise<void> {
		if (this.file !== null && this.isValidFile(this.file) && this.externalSend !== false) {
			this.loading = true
			await this.updateRender()
			const formParameters = await getFormParameters(this.$store.state.jwt, this.file.name, this.verificationFactor, this.repeatableId)
			this.sent = (formParameters !== undefined) ? await uploadVerificationFactorFile(formParameters, this.file) : null
      this.$emit('uploaded')
			this.loading = false
			await this.updateRender()
		}
		if (this.externalSend !== undefined) {
			this.$emit('updateFile', (this.file !== null && this.isValidFile(this.file)))
			this.$emit('sent', this.sent)
		}
	}
}
