import { render, staticRenderFns } from "./VerificationFactorFileInput.vue?vue&type=template&id=f2d9b702&scoped=true&"
import script from "./VerificationFactorFileInput.vue?vue&type=script&lang=ts&"
export * from "./VerificationFactorFileInput.vue?vue&type=script&lang=ts&"
import style0 from "./VerificationFactorFileInput.vue?vue&type=style&index=0&id=f2d9b702&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.7_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d9b702",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.2_vue-template-compiler@2.6.14_vuetify@2.6.6_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VFileInput,VIcon,VTooltip})
